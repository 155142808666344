@use '@angular/material' as mat;
@import './generate-mat-colors';
@import './common-theme';

@include mat.core();
@include mat.core-theme($PathOfficeClient-theme-light);
@include mat.button-theme($PathOfficeClient-theme-light);

:root {
  @include generateColors('primary', $primary-light-palette);
  --primary-text-color: rgba(black, 0.87);
}

$primaryLightColor: map-get($PathOfficeClient-theme-light, primary);

.settingsDialog .mat-expansion-panel,
.settingsDialog .mat-expansion-panel-header {
  background-color: map-get($primary-light-palette, 50) !important;

  &:hover {
    background-color: map-get($primary-light-palette, 50) !important;
  }
}

.action-toolbar {
  background-color: #f9f9f9 !important;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: white !important;
  --mat-mdc-snack-bar-button-color: map-get(
    $primary-light-palette,
    500
  ) !important;
  --mdc-snackbar-supporting-text-color: black !important;
}

.table-toolbar {
  background-color: white;
}

/*выделение области вложенного редактора*/
.insideEditor {
  border-radius: 10px;
  padding: 10px;
  background-color: map-get($primaryLightColor, 50);
}

.insideInsideEditor {
  border-radius: 10px;
  padding: 10px;
  background-color: map-get($primaryLightColor, 100);
}

@include mat.all-component-themes($PathOfficeClient-theme-light);

.accent-cl {
  color: map-get($primary-light-palette, 500);
}
.accent-cl-bg {
  background: map-get($primary-light-palette, 500);
}

.node-label:hover,
.node-label.selected {
  color: map-get($primaryLightColor, 400);
}

.dot {
  background: map-get($primary-light-palette, 500);
}

.toggle-dot {
  background: map-get($primary-light-palette, 200);
  &.active {
    background: map-get($primary-light-palette, 500);
  }
}
