@use '@angular/material' as mat;
@import url('../../node_modules/material-design-icons-iconfont/dist/material-design-icons.css');
@import './palette/light';
@import './palette/dark';

@include mat.core();

$body-1: mat.define-typography-level(
  $font-size: 14px,
  $line-height: 18px,
);
$caption: mat.define-typography-level(
  $font-size: 12px,
  $line-height: 14px,
);

$custom-typography: mat.define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $body-1: $body-1,
  $caption: $caption,
);

// light
$PathOfficeClient-primary-light: mat.define-palette($primary-light-palette);
$PathOfficeClient-accent-light: mat.define-palette(mat.$amber-palette);
$PathOfficeClient-theme-light: mat.define-light-theme(
  (
    color: (
      primary: $PathOfficeClient-primary-light,
      accent: $PathOfficeClient-accent-light,
    ),
    typography: $custom-typography,
  )
);

// dark
$PathOfficeClient-primary-dark: mat.define-palette($primary-dark-palette);
$PathOfficeClient-accent-dark: mat.define-palette(mat.$red-palette);
$PathOfficeClient-theme-dark: mat.define-dark-theme(
  (
    color: (
      primary: $PathOfficeClient-primary-dark,
      accent: $PathOfficeClient-accent-dark,
    ),
    // Only include `typography` and `density` in the default dark theme.
    typography: $custom-typography,
    density: 0,
  )
);

@include mat.all-component-typographies($custom-typography);

.root-brand-logo-company-wrapper {
  &.light {
    background: white;
  }

  &.dark {
    $primaryLightColor: map-get($PathOfficeClient-theme-light, primary);
    background: map_get($primary-dark-palette, 800);
  }
}

// хотим одинаковый для темной и светлой темы
.root-brand-logo-product-wrapper {
  $primaryLightColor: map-get($PathOfficeClient-theme-light, primary);
  background: map_get($primary-light-palette, 500);
}

.mat-slide-toggle-bar {
  transform: scale(0.8);
  margin-right: 5px !important;
}

/*Перекраска скроллбаров и изменение размера*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px var(--po-primary-900);
  border-radius: 10px;
  background-color: var(--po-primary-900);
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: var(--po-primary-200);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0 3px var(--po-primary-200);
  background-color: var(--po-primary-300);
}

input:-webkit-autofill {
  background: var(--po-primary-500);
  color: white;
}

.mat-slide-toggle-thumb {
  margin-top: 2px;
  width: 16px !important;
  height: 16px !important;
}

.mat-slide-toggle-bar {
  width: 32px !important;
}

// paginator text color
.mat-mdc-paginator {
  color: #999999 !important;
}
