@mixin generateColors($prefix, $palette) {
  $colors-map: ();

  @each $key, $value in $palette {
    @if $key !=contrast {
      .app-#{$prefix}-#{$key} {
        color: map-get($palette, $key);
      }

      $map: ();
      $map: map-merge(
        $map,
        (
          $key: $value,
        )
      );
      $colors-map: map-merge($colors-map, $map);
    }
  }

  @each $key, $value in $colors-map {
    --po-#{$prefix}-#{$key}: #{$value};
  }
}

//@mixin generate-material-classes($primary-palette, $accent-palette) {
//  @include generateColors(primary, $primary-palette);
//  @include generateColors(accent, $accent-palette);
//}
